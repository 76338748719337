
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from 'moment';
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue3-xlsx";
export default defineComponent({
  name: "upload-disbursement-data",
  components: {
    Form,
    Field,
    Datatable,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  computed: {
    downloadURL() {
      const fileURL = '/excel/Disbursement Report.xlsx';
      return fileURL
    }
  },


  data() {
    return {
      moment: '' as any,
      loading: false,
      showExcelData: false,
      batch: {},
      employee: {},
      details: {},
      file: "" as any,
      selectedSheet: null,
      sheetName: null,
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      disbloading: false,
      excelHeader: [
        {
          name: "Action",
          key: "action",
          sortable: false,
          width: "5px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: false,
          width: "5px",
        },
        {
          name: "Trainee Name",
          key: "trainee_name",
          sortable: false,
        },
        {
          name: "Registration Number",
          key: "registration_number",
          sortable: false,
          width: '120px'
        },
         {
          name: "Mobile",
          key: "mobile",
          sortable: false,
        },
        {
          name: "Bank Account Number",
          key: "bank_account_number",
          sortable: false,
          width: '120px'
        },
       
         {
          name: "Disbursed Amount",
          key: "disbursed_amount",
          sortable: false,
        },
        {
          name: "Disbursement Date",
          key: "disbursement_date",
          sortable: false,
        },
        {
          name: "Disbursement Remarks",
          key: "disbursement_remarks",
          sortable: false,
        },

      ],
      excelData: [

      ] as any,
      disburse_upload_data: [

      ] as any,
      showUpdateDisBtn: false,
      componentKey: 0,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      load: false,

    };

  },
  async created() {
    this.moment = moment;
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {

    async formSubmit() {
      this.loading = true;
      this.load = true;
      let data = new FormData();
      data.append('file', this.file);
      ApiService.post("stipend/import", data).then((response) => {
        this.loading = false;
        if (response.data.status == 'success') {
          //  this.$refs.fileInput.value = '';
          this.file = [] as any;

          this.excelData = response.data.data;
          this.showExcelData = true;
          this.showUpdateDisBtn = true;


          this.load = false;


        }
        if (response.data.status == 'error') {

          Swal.fire({
            title: " Error",
            html: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
        }


        console.log(response)
      }).catch((response) => {
        this.load = false;
        this.showExcelData = false;

        Swal.fire({
          title: " Error",
          html: response.data.data,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
        console.log(response);
      })
    },
    async disbursementUpdate() {
      this.disbloading = true;
      this.load = true;
      let data = new FormData();
      for (let i = 0; i < this.excelData.length; i++) {
        var disburse_data = {
          registration_number: this.excelData[i]?.registration_number,
          trainee_name: this.excelData[i]?.trainee_name,
          gender: this.excelData[i]?.gender,
          account_type: this.excelData[i]?.account_type,
          bank_account_number: this.excelData[i]?.bank_account_number,
          mobile: this.excelData[i]?.mobile,
          email: this.excelData[i]?.email,
          father_name: this.excelData[i]?.father_name,
          mother_name: this.excelData[i]?.mother_name,
          bank_name: this.excelData[i]?.bank_name,
          bank_verified: this.excelData[i]?.bank_verified,

          stipend_disbursed: this.excelData[i]?.stipend_disbursed == 'Y' ? 1 : 0,
          disbursement_date: this.excelData[i]?.disbursement_date,
          disbursed_amount: this.excelData[i]?.disbursed_amount,
          disbursement_remarks: this.excelData[i]?.disbursement_remarks,

        };

        this.disburse_upload_data.push(disburse_data);
      }

      data.set('disburse_data', (JSON.stringify(this.disburse_upload_data)));
      ApiService.post("stipend/disburseUpdate", data).then((response) => {
        this.disbloading = false;
        if (response.data.status == 'success') {

          this.excelData = response.data.data;
          this.componentKey += 1;
          this.showExcelData = true;
          this.showUpdateDisBtn = false;


          this.disbloading = false;

          this.load = false;


          Swal.fire({
            text: "Upload Successfully Done!",
            title: "Success",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-success",
            },
          })
        }


        console.log(response)
      }).catch((response) => {
        this.load = false;
        this.showExcelData = false;

        Swal.fire({
          title: " Error",
          html: response.data.data,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
        console.log(response);
      })
    },
    onChange(event) {
      console.log();
      let filename = event.target.files[0]?.name;

      this.file = [];
      this.file = event.target.files ? event.target.files[0] : null;

    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
